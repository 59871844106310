<template>
  <div class="webhtml" style="overflow-x: scroll">
    <div v-if="GuiNodeCh">
      <div v-for="item in GuiNodeCh" :key="item.Xnodekey" class="m-b-1">
        <!-- Button -->
        <NodeControls :data="item" :component="item.XcontrolChild" :update-node-key="Xnodekey"></NodeControls>
      </div>
    </div>

    <!-- Report -->
    <span v-if="ReportInHtml" v-html="ReportInHtml"></span>
  </div>
</template>

<script>
import { get } from 'lodash';
import mixins from '@/shared/mixins/index';

export default {
  name: 'WEBHTML',

  mixins: [mixins],

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    GuiNodeCh() {
      return get(this.data, 'GuiNodeCh');
    },

    ReportInHtml() {
      return get(this.data, 'ReportInHtml');
    },

    Xnodekey() {
      return get(this.data, 'Xnodekey');
    }
  }
};
</script>
